export var measureTextWidth = function measureTextWidth(_ref) {
    var text = _ref.text, _ref$font = _ref.font, font = _ref$font === void 0 ? '10px FTPolar' : _ref$font;
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    if (!context) {
        return 0;
    }
    context.font = font;
    return context.measureText(text).width;
};
