import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _this = this;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import moment from 'moment';
import { Features } from '../data/constants';
export var getPercentage = function getPercentage(_ref) {
    var part = _ref.part, total = _ref.total, _ref$decimals = _ref.decimals, decimals = _ref$decimals === void 0 ? 0 : _ref$decimals;
    return (total !== 0 ? part / total * 100 : 0).toFixed(decimals);
};
export var formatNumberLocale = function formatNumberLocale(value) {
    return typeof value === 'number' ? value.toLocaleString() : value;
};
export var oneYearAgo = moment().subtract(12, 'months').format('YYYY-MM-DD');
export var oneYearUntil = function oneYearUntil(date) {
    return moment(date).subtract(12, 'months').format('YYYY-MM-DD');
};
export var today = function today() {
    return moment().format('YYYY-MM-DD');
};
export var debounce = function debounce(fn, ms) {
    var timer;
    return function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }
        clearTimeout(timer);
        timer = setTimeout(function (_) {
            timer = null;
            fn.call.apply(fn, [_this].concat(args));
        }, ms);
    };
};
export var getDateRange = function getDateRange(_ref2) {
    var fromDate = _ref2.fromDate, untilDate = _ref2.untilDate, _ref2$aggregation = _ref2.aggregation, aggregation = _ref2$aggregation === void 0 ? 'month' : _ref2$aggregation;
    var dateRange = [];
    var currentDate = moment(fromDate).startOf('day');
    var lastDate = moment(untilDate).startOf('day');
    while (currentDate.isSameOrBefore(lastDate, aggregation)) {
        dateRange.push(currentDate.clone());
        currentDate.add(1, aggregation);
    }
    switch (aggregation) {
        case 'year':
            return dateRange.map(function (date) {
                return {
                    year: date.year()
                };
            });
        case 'day':
            return dateRange.map(function (date) {
                return {
                    year: date.year(),
                    month: date.month() + 1,
                    day: date.date()
                };
            });
        default:
            return dateRange.map(function (date) {
                return {
                    year: date.year(),
                    month: date.month() + 1
                };
            });
    }
};
export var getPrevFromDate = function getPrevFromDate(_ref3) {
    var fromDate = _ref3.fromDate, untilDate = _ref3.untilDate;
    var fromMoment = moment(fromDate);
    var untilMoment = moment(untilDate);
    var diff = untilMoment.diff(fromMoment, 'days');
    return fromMoment.subtract(diff, 'days').format('YYYY-MM-DD');
};
export var getFactoriesByMarket = function getFactoriesByMarket(factories) {
    return factories.reduce(function (acc, factory) {
        var _factory$market, _factory$market$name, _factory$market3, _factory$market3$name, _factory$market4, _factory$market4$name;
        if (!acc[(_factory$market = factory.market) === null || _factory$market === void 0 ? void 0 : (_factory$market$name = _factory$market.name) === null || _factory$market$name === void 0 ? void 0 : _factory$market$name.toLowerCase()]) {
            var _factory$market2, _factory$market2$name;
            return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, (_factory$market2 = factory.market) === null || _factory$market2 === void 0 ? void 0 : (_factory$market2$name = _factory$market2.name) === null || _factory$market2$name === void 0 ? void 0 : _factory$market2$name.toLowerCase(), [factory]));
        }
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, (_factory$market3 = factory.market) === null || _factory$market3 === void 0 ? void 0 : (_factory$market3$name = _factory$market3.name) === null || _factory$market3$name === void 0 ? void 0 : _factory$market3$name.toLowerCase(), [].concat(_toConsumableArray(acc[(_factory$market4 = factory.market) === null || _factory$market4 === void 0 ? void 0 : (_factory$market4$name = _factory$market4.name) === null || _factory$market4$name === void 0 ? void 0 : _factory$market4$name.toLowerCase()]), [factory])));
    }, {});
};
export var getExcludedDatesRange = function getExcludedDatesRange(_ref4) {
    var fromDate = _ref4.fromDate, untilDate = _ref4.untilDate;
    var dateRange = [];
    var currentDate = moment(fromDate);
    var lastDate = moment(untilDate);
    while (currentDate.isSameOrBefore(lastDate)) {
        dateRange.push(new Date(currentDate.format('YYYY-MM-DD')));
        currentDate.add(1, 'day');
    }
    return dateRange;
};
export var matchFacilityType = function matchFacilityType(factory, facilityType) {
    if (!facilityType || facilityType === 'all') {
        return true;
    }
    return factory.facilityType === facilityType;
};
export var facilityHasValidServiceAgreementPeriod = function facilityHasValidServiceAgreementPeriod(facility) {
    var contractStartDateValid = moment(facility === null || facility === void 0 ? void 0 : facility.contractStartDate, 'x').isValid();
    var contractEndDateValid = moment(facility === null || facility === void 0 ? void 0 : facility.contractEndDate, 'x').isValid();
    return contractStartDateValid && contractEndDateValid;
};
export var isGoFacility = function isGoFacility(f) {
    return f.type === 'go';
};
export var nonGoFacility = function nonGoFacility(f) {
    return f.type !== 'go';
};
export var isInvalidatedFacility = function isInvalidatedFacility(f) {
    return f === null || f === void 0 ? void 0 : f.isInvalidated;
};
export var isTestFacility = function isTestFacility(f) {
    return f === null || f === void 0 ? void 0 : f.isTestFactory;
};
export var isFactory = function isFactory(f) {
    return matchFacilityType(f, 'production_unit');
};
export var isLaborAgent = function isLaborAgent(f) {
    return matchFacilityType(f, 'labor_agent');
};
export var tryParseJson = function tryParseJson(value) {
    try {
        return JSON.parse(value);
    }
    catch (_unused) {
        return value;
    }
};
export var toSnakeCase = function toSnakeCase(str) {
    if (typeof str !== 'string') {
        return str;
    }
    return str.replace(/([A-Z]{1})/g, function (_, capitalizedChar) {
        return "_".concat(capitalizedChar.toLowerCase());
    });
};
export var checkHideIntroVideo = function checkHideIntroVideo(features) {
    return features === null || features === void 0 ? void 0 : features.includes(Features.HideIntroVideo);
};
export * from './auth';
export * from './api';
export * from './demoMode';
export * from './color';
export * from './storeQuestions';
export * from './getQuestion';
export * from './os';
export * from './getUniqueUserItems';
export * from './getJobLevelsFromDynamicView';
export * from './customViewFilters';
export * from './joyride';
export { default as sortByKey } from './sortByKey';
export { default as printDocument } from './printDocument';
export { default as convertToCsv } from './convertToCsv';
export { default as downloadCsv } from './downloadCsv';
export { default as downloadXLSX } from './downloadXLSX';
export { default as getRequiredSessions } from './getRequiredSessions';
export { default as getRequiredSessionsPerDay } from './getRequiredSessionsPerDay';
export { default as updateFilters } from './updateFilter';
export { default as updateFiltersGo } from './updateFiltersGo';
export { default as updateFiltersOverview } from './updateFilterOverview';
export * from './dateAggregation';
export { default as featureEnabled, flags, isAdmin, isAccountOwnerOrAdmin, canAccessDashboard, BooleanFlaggedFeatures } from './featureFlags';
export * from './useQueryParams';
export * from './useAsyncFunction';
export * from './usePrevious';
export * from './getAppTrainingUrl';
export * from './getSignupLinkForTeam';
export * from './downloadQRCode';
export * from './shouldEnableStartTraining';
export * from './checkSecondaryOwner';
export * from './shouldPageDisplayStartTrainingButton';
export * from './shouldPageHideCookieConsent';
export * from './finishRegister';
export * from './getActiveTrainings';
export * from './downloadFilesUrlAsZip';
export * from './training';
export * from './email';
export * from './measureTextWidth';
