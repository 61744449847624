export var getTrainingSeatsSummary = function getTrainingSeatsSummary(_ref) {
    var _find;
    var programId = _ref.programId, trainedPlayersStats = _ref.trainedPlayersStats, account = _ref.account;
    var trainedPlayersStatsOfProgram = trainedPlayersStats.find(function (stats) {
        return stats.programId === programId;
    });
    var used = (trainedPlayersStatsOfProgram === null || trainedPlayersStatsOfProgram === void 0 ? void 0 : trainedPlayersStatsOfProgram.playersCount) || 0;
    var total = (_find = (account.accessLimitationsByPrograms || []).find(function (accessLimitation) {
        return accessLimitation.programId === programId;
    })) === null || _find === void 0 ? void 0 : _find.numberOfSeats;
    return {
        used: used,
        total: total,
        isOutOfSeats: total ? used >= total : undefined
    };
};
