import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import { useLazyQuery, useQuery as apolloUseQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';
var DEFAULT_FETCH_POLICY = 'cache-first';
export var useQuery = function useQuery(_ref) {
    var query = _ref.query, _ref$variables = _ref.variables, variables = _ref$variables === void 0 ? {} : _ref$variables, _ref$fetchPolicy = _ref.fetchPolicy, fetchPolicy = _ref$fetchPolicy === void 0 ? DEFAULT_FETCH_POLICY : _ref$fetchPolicy, _ref$client = _ref.client, client = _ref$client === void 0 ? undefined : _ref$client;
    return apolloUseQuery(query, _objectSpread({
        variables: variables,
        fetchPolicy: fetchPolicy,
        notifyOnNetworkStatusChange: true
    }, client ? {
        client: client
    } : {}));
};
export var useImperativeQuery = function useImperativeQuery(query) {
    var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {}, _ref2$fetchPolicy = _ref2.fetchPolicy, fetchPolicy = _ref2$fetchPolicy === void 0 ? DEFAULT_FETCH_POLICY : _ref2$fetchPolicy;
    var _useLazyQuery = useLazyQuery(query, {
        fetchPolicy: fetchPolicy,
        notifyOnNetworkStatusChange: true
    }), _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2), lazyQuery = _useLazyQuery2[0], _useLazyQuery2$ = _useLazyQuery2[1], data = _useLazyQuery2$.data, loading = _useLazyQuery2$.loading, error = _useLazyQuery2$.error;
    var promise = useRef();
    useEffect(function () {
        if (data && promise.current) {
            promise.current.resolve(data);
            promise.current = null;
        }
    }, [data]);
    useEffect(function () {
        if (error && promise.current) {
            promise.current.reject(error);
            promise.current = null;
        }
    }, [error]);
    var imperativeQuery = function imperativeQuery(variables) {
        return new Promise(function (resolve, reject) {
            promise.current = {
                resolve: resolve,
                reject: reject
            };
            lazyQuery({
                variables: variables
            });
        });
    };
    return [imperativeQuery, {
            data: data,
            error: error,
            loading: loading
        }];
};
export default useImperativeQuery;
