import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { Features, SignUpSteps } from '../data/constants';
import { AccountType, UserRole } from '../data/enums';
export var checkShouldAskFacilityBrandId = function checkShouldAskFacilityBrandId(_ref) {
    var _signupFeaturePackage, _facilityOwnerAccount;
    var user = _ref.user, signupFeaturePackage = _ref.signupFeaturePackage;
    if (!user || !signupFeaturePackage || !((_signupFeaturePackage = signupFeaturePackage.signupSteps) !== null && _signupFeaturePackage !== void 0 && _signupFeaturePackage.some(function (step) {
        var _step$features;
        return step.id === SignUpSteps.create_factory && ((_step$features = step.features) === null || _step$features === void 0 ? void 0 : _step$features.some(function (f) {
            return f === Features.AskFacilityBrandId;
        }));
    }))) {
        return false;
    }
    var accounts = user.accounts, featurePackage = user.featurePackage, factories = user.factories, role = user.role;
    var isOwnerUser = role === UserRole.ACCOUNT_OWNER;
    var facilityOwnerAccount = isOwnerUser && (!featurePackage || (featurePackage === null || featurePackage === void 0 ? void 0 : featurePackage.accountType) === AccountType.TRAINING_FACILITY) ? accounts.find(function (acc) {
        var _acc$factories;
        return (_acc$factories = acc.factories) === null || _acc$factories === void 0 ? void 0 : _acc$factories.length;
    }) : undefined;
    if (!facilityOwnerAccount) {
        return false;
    }
    var hasFacilityBrandId = (_facilityOwnerAccount = facilityOwnerAccount.factories) === null || _facilityOwnerAccount === void 0 ? void 0 : _facilityOwnerAccount.every(function (facilityId) {
        return factories.some(function (f) {
            var _f$facilityBrandIds;
            return f._id.toString() === facilityId.toString() && ((_f$facilityBrandIds = f.facilityBrandIds) === null || _f$facilityBrandIds === void 0 ? void 0 : _f$facilityBrandIds.some(function (fbi) {
                return fbi.id && signupFeaturePackage.brand === fbi.brand;
            }));
        });
    });
    return !hasFacilityBrandId;
};
export var getTrainingFacility = function getTrainingFacility(user) {
    var facilityOwnerAccount = user === null || user === void 0 ? void 0 : user.accounts.find(function (acc) {
        return acc.factories.length > 0;
    });
    if (!facilityOwnerAccount) {
        return undefined;
    }
    var facility = user.factories.find(function (f) {
        return f._id.toString() === facilityOwnerAccount.factories[0].toString();
    });
    if (!facility) {
        return undefined;
    }
    return facility;
};
export var checkByPassAskFacilityBrandId = function checkByPassAskFacilityBrandId(_ref2) {
    var _signupFeaturePackage2, _user$featurePackage, _facility$facilityBra, _facility$facilityBra2;
    var user = _ref2.user, signupFeaturePackage = _ref2.signupFeaturePackage;
    var facility = getTrainingFacility(user);
    if (!facility) {
        return false;
    }
    var isSigningUpWithBrandPartner = !!(signupFeaturePackage !== null && signupFeaturePackage !== void 0 && (_signupFeaturePackage2 = signupFeaturePackage.partners) !== null && _signupFeaturePackage2 !== void 0 && _signupFeaturePackage2.includes(((_user$featurePackage = user.featurePackage) === null || _user$featurePackage === void 0 ? void 0 : _user$featurePackage.brand) || ''));
    var existingFacilityBrandId = (_facility$facilityBra = facility.facilityBrandIds) === null || _facility$facilityBra === void 0 ? void 0 : _facility$facilityBra.find(function (fbi) {
        var _user$featurePackage2;
        return fbi.id && ((_user$featurePackage2 = user.featurePackage) === null || _user$featurePackage2 === void 0 ? void 0 : _user$featurePackage2.brand) === fbi.brand;
    });
    var existingFacilityBrandIdWithNewSignUp = (_facility$facilityBra2 = facility.facilityBrandIds) === null || _facility$facilityBra2 === void 0 ? void 0 : _facility$facilityBra2.find(function (fbi) {
        return fbi.id && signupFeaturePackage.brand === fbi.brand;
    });
    return isSigningUpWithBrandPartner && !!existingFacilityBrandId && !existingFacilityBrandIdWithNewSignUp;
};
export var getAskUsesLaborAgentsForTrainingIds = function getAskUsesLaborAgentsForTrainingIds(_ref3) {
    var _signupFeaturePackage3;
    var user = _ref3.user, signupFeaturePackage = _ref3.signupFeaturePackage;
    if (!user || !signupFeaturePackage || !((_signupFeaturePackage3 = signupFeaturePackage.signupSteps) !== null && _signupFeaturePackage3 !== void 0 && _signupFeaturePackage3.some(function (step) {
        var _step$features2;
        return step.id === SignUpSteps.create_factory && ((_step$features2 = step.features) === null || _step$features2 === void 0 ? void 0 : _step$features2.some(function (f) {
            return f === Features.AskFacilityLaborAgentUsage;
        }));
    }))) {
        return {
            trainingIds: []
        };
    }
    var accounts = user.accounts, featurePackage = user.featurePackage, role = user.role;
    var isOwnerUser = role === UserRole.ACCOUNT_OWNER;
    var facilityOwnerAccount = isOwnerUser && (!featurePackage || (featurePackage === null || featurePackage === void 0 ? void 0 : featurePackage.accountType) === AccountType.TRAINING_FACILITY) ? accounts.find(function (acc) {
        var _acc$factories2;
        return (_acc$factories2 = acc.factories) === null || _acc$factories2 === void 0 ? void 0 : _acc$factories2.length;
    }) : undefined;
    if (!facilityOwnerAccount) {
        return {
            trainingIds: []
        };
    }
    var trainingIds = askUsesLaborAgentsForTrainingIds(signupFeaturePackage, facilityOwnerAccount);
    if (!trainingIds.length) {
        return {
            trainingIds: []
        };
    }
    if (!Array.isArray(facilityOwnerAccount === null || facilityOwnerAccount === void 0 ? void 0 : facilityOwnerAccount.usesLaborAgentsForTrainingIds)) {
        return {
            skipAskUsesLaborAgents: false,
            trainingIds: trainingIds
        };
    }
    return {
        skipAskUsesLaborAgents: true,
        trainingIds: _toConsumableArray(new Set([].concat(_toConsumableArray((facilityOwnerAccount === null || facilityOwnerAccount === void 0 ? void 0 : facilityOwnerAccount.usesLaborAgentsForTrainingIds) || []), trainingIds)))
    };
};
export var shouldAskAdditionalInformationStep = function shouldAskAdditionalInformationStep(signupFeaturePackage) {
    var _signupFeaturePackage4;
    return signupFeaturePackage === null || signupFeaturePackage === void 0 ? void 0 : (_signupFeaturePackage4 = signupFeaturePackage.signupSteps) === null || _signupFeaturePackage4 === void 0 ? void 0 : _signupFeaturePackage4.some(function (step) {
        return step.id === SignUpSteps.ask_additional_information;
    });
};
var askUsesLaborAgentsForTrainingIds = function askUsesLaborAgentsForTrainingIds(signupFeaturePackage, facilityOwnerAccount) {
    var _signupFeaturePackage5, _signupFeaturePackage6, _signupFeaturePackage7;
    var signupStepCreateFactory = (_signupFeaturePackage5 = signupFeaturePackage.signupSteps) === null || _signupFeaturePackage5 === void 0 ? void 0 : (_signupFeaturePackage6 = _signupFeaturePackage5.find(function (step) {
        return step.id === SignUpSteps.create_factory;
    })) === null || _signupFeaturePackage6 === void 0 ? void 0 : (_signupFeaturePackage7 = _signupFeaturePackage6.features) === null || _signupFeaturePackage7 === void 0 ? void 0 : _signupFeaturePackage7.some(function (id) {
        return id === Features.AskFacilityLaborAgentUsage;
    });
    var trainingIds = signupStepCreateFactory ? signupFeaturePackage.trainingIds : [];
    if (!Array.isArray(facilityOwnerAccount === null || facilityOwnerAccount === void 0 ? void 0 : facilityOwnerAccount.usesLaborAgentsForTrainingIds)) {
        return trainingIds;
    }
    if (!facilityOwnerAccount.usesLaborAgentsForTrainingIds.length) {
        return [];
    }
    return trainingIds.filter(function (trainingId) {
        var _facilityOwnerAccount2;
        return !((_facilityOwnerAccount2 = facilityOwnerAccount.usesLaborAgentsForTrainingIds) !== null && _facilityOwnerAccount2 !== void 0 && _facilityOwnerAccount2.includes(trainingId));
    });
};
