export var SUPPORT_EMAIL = 'support@quizrr.se';
export var getMailToLink = function getMailToLink(_ref) {
    var email = _ref.email, cc = _ref.cc, bcc = _ref.bcc, _ref$subject = _ref.subject, subject = _ref$subject === void 0 ? '' : _ref$subject, _ref$body = _ref.body, body = _ref$body === void 0 ? '' : _ref$body;
    var link = 'mailto:' + email + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
    if (cc) {
        link += "&cc=".concat(cc);
    }
    if (bcc) {
        link += "&bcc=".concat(bcc);
    }
    return link;
};
