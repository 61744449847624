import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import { tryParseJson } from '../../utils';
import { ViewName as _jsdoc_ViewName, DynamicViewType as _jsdoc_DynamicViewType, View as _jsdoc_View, DynamicViewMergeOptions as _jsdoc_DynamicViewMergeOptions } from './types';
import { DynamicFilters } from './dynamicFilters';
export var DynamicView = function () {
    function DynamicView(dynamicView) {
        _classCallCheck(this, DynamicView);
        this._filters = new DynamicFilters(dynamicView.filters || []);
        this._views = dynamicView.views || [];
    }
    return _createClass(DynamicView, [{
            key: "state",
            value: function state() {
                return {
                    filters: this._filters.state(),
                    views: this._views.map(function (view) {
                        return Object.freeze(_objectSpread(_objectSpread({}, view), {}, {
                            __typename: 'DynamicView'
                        }));
                    })
                };
            }
        }, {
            key: "filters",
            value: function filters() {
                return this._filters;
            }
        }, {
            key: "merge",
            value: function merge(dynamicView) {
                var mergeOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DynamicView.defaultMergeOptions();
                if (!dynamicView) {
                    return this;
                }
                return new DynamicView({
                    filters: this._filters.merge(dynamicView.filters || [], mergeOptions).state(),
                    views: this._mergeViews(dynamicView.views || [])
                });
            }
        }, {
            key: "_mergeViews",
            value: function _mergeViews(views) {
                var mergedViews = _toConsumableArray(this._views);
                views.forEach(function (view) {
                    var index = mergedViews.findIndex(function (existingView) {
                        return existingView.name === view.name;
                    });
                    if (index > -1) {
                        mergedViews[index] = _objectSpread(_objectSpread({}, mergedViews[index]), view);
                    }
                    else {
                        mergedViews.push(view);
                    }
                });
                return mergedViews;
            }
        }, {
            key: "visible",
            value: function visible(viewName) {
                return this._views.some(function (view) {
                    return view.name === viewName && view.visible;
                });
            }
        }, {
            key: "get",
            value: function get(viewName) {
                return Object.freeze(this._views.find(function (view) {
                    return view.name === viewName;
                }) || {});
            }
        }], [{
            key: "default",
            value: function _default() {
                return new DynamicView({
                    filters: config.dynamicViewCore.filters,
                    views: config.dynamicViewCore.views
                });
            }
        }, {
            key: "defaultGo",
            value: function defaultGo() {
                return new DynamicView({
                    filters: config.dynamicViewGo.filters,
                    views: config.dynamicViewGo.views
                });
            }
        }, {
            key: "defaultOverview",
            value: function defaultOverview() {
                return new DynamicView({
                    filters: [{
                            name: 'market',
                            displayType: 'multiSelectDropdown',
                            visible: true,
                            allowAll: true
                        }, {
                            name: 'facilityType',
                            displayType: 'multiTag',
                            visible: true,
                            allowAll: true
                        }, {
                            name: 'organization',
                            displayType: 'dropdown',
                            visible: true,
                            allowAll: true
                        }, {
                            name: 'brand',
                            displayType: 'dropdown',
                            visible: true,
                            allowAll: true
                        }, {
                            name: 'facilities',
                            displayType: 'multiSelectDropdown',
                            visible: true,
                            allowAll: true
                        }].concat(_toConsumableArray(config.dynamicViewCore.filters)),
                    views: config.dynamicViewCore.views
                });
            }
        }, {
            key: "defaultMergeOptions",
            value: function defaultMergeOptions() {
                return _objectSpread({}, DynamicFilters.defaultMergeOptions());
            }
        }]);
}();
export function deserialize(dynamicView) {
    if (!dynamicView) {
        return null;
    }
    return _objectSpread(_objectSpread({}, dynamicView), {}, {
        views: (dynamicView.views || []).map(function (view) {
            return _objectSpread({}, view);
        }),
        filters: (dynamicView.filters || []).map(function (filter) {
            return _objectSpread(_objectSpread({}, filter), {}, {
                options: tryParseJson(filter.options),
                defaultOptions: tryParseJson(filter.defaultOptions)
            });
        })
    });
}
